const initStorageOnPlanSelect = ({ selectedPlan, planContent }) => {
  sessionStorage?.setItem("registration_price_id", selectedPlan?.priceId)
  sessionStorage?.setItem("registration_plan", planContent.plan)
  sessionStorage?.setItem("membership_plan", "free")

  if (planContent.plan === "pre_planning_premium") {
    sessionStorage?.setItem("membership_plan", "premium_subscription")
  }
}

export { initStorageOnPlanSelect }
