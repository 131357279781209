import React, { useState } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import MembershipPickerGroup from "../components/pricing/membership-picker-group"
import { PricingProvider } from "../context/pricing-context"
import { initStorageOnPlanSelect } from "../utils/register"
import parse from "html-react-parser"
import Newsletter from "../components/homepage/newsletter"
import { useUser } from "../context/user-context"
import UpgradePaywallModal from "../components/checkout/upgrade-paywall-modal"
import UpgradeFlow from "../components/checkout/upgrade-flow"

function PricingPage() {
  const { prismicPricingPage } = useStaticQuery(graphql`
    query pricingPageQuery {
      prismicPricingPage {
        data {
          title {
            text
          }
          subtitle {
            html
          }
          preplanning_title {
            text
          }
          after_loss_title {
            text
          }
          after_loss_subtitle {
            html
          }
          preplanning_subtitle {
            html
          }
          preplanning_memberships {
            picker {
              document {
                ... on PrismicMembershipPicker {
                  id
                  data {
                    heading {
                      html
                      text
                    }
                    plan
                    subheading {
                      html
                      text
                    }
                    features {
                      active
                      feature {
                        html
                        text
                      }
                    }
                    trial_button_text {
                      text
                    }
                    purchase_button_text {
                      text
                    }
                    external_link {
                      url
                    }
                  }
                }
              }
            }
          }
          after_loss_memberships {
            picker {
              document {
                ... on PrismicMembershipPicker {
                  id
                  data {
                    heading {
                      html
                      text
                    }
                    plan
                    subheading {
                      html
                      text
                    }
                    features {
                      active
                      feature {
                        html
                        text
                      }
                    }
                    trial_button_text {
                      text
                    }
                    purchase_button_text {
                      text
                    }
                    external_link {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    title,
    subtitle,
    after_loss_title,
    after_loss_subtitle,
    preplanning_title,
    preplanning_subtitle,
    after_loss_memberships,
    preplanning_memberships,
  } = prismicPricingPage.data

  const user = useUser()
  const premiumMemberships = []
  const freeMemberships = []
  const [isUpgradeFlowVisible, setIsUpgradeFlowVisible] = useState(false)
  after_loss_memberships.forEach((element) => {
    freeMemberships.push(element.picker.document.data)
  })

  preplanning_memberships.forEach((element) => {
    premiumMemberships.push(element.picker.document.data)
  })

  const onPlanSelect = ({ selectedPlan, planContent, makePayment }) => {
    initStorageOnPlanSelect({ selectedPlan, planContent })
    if (!user && !makePayment) {
      navigate("/register?scenario=managing-a-death")
    }
    if (!user && makePayment) {
      navigate("/register?premium=true")
    }
    if (user) setIsUpgradeFlowVisible(true)
  }

  return (
    <Layout>
      <PricingProvider>
        <div id="pricing-page">
          <div className="md:mx-6">
            <div className="max-w-body mx-auto px-6 md:px-0 pt-12 pb-24 md:pb-32">
              <section className="header pt-12 pb-6 flex flex-col">
                <div className="headline-text text-white">
                  <h1>{title.text}</h1>
                </div>
                <div className="sub-headline-text text-white">
                  <div>{parse(subtitle.html)}</div>
                </div>
              </section>
              <section className="flex flex-col md:flex-row md:space-x-3 space-y-8 md:space-y-0">
                <div
                  className="flex flex-col w-full md:w-1/2 bg-primary-lighter border-2 
                  border-primary-lighter rounded-xl"
                >
                  <div className="pt-6 pb-2 text-center leading-snug">
                    <h3 className="font-semibold text-gray-dark text-2xl mb-2">
                      {after_loss_title.text}
                    </h3>
                    <div className="text-sm px-4">
                      {parse(after_loss_subtitle.html)}
                    </div>
                  </div>
                  <div className="flex-grow w-full bg-grey-lighter rounded-b-xl px-4 pt-4 pb-6">
                    <MembershipPickerGroup
                      memberships={freeMemberships}
                      onPlanSelect={onPlanSelect}
                    />
                  </div>
                </div>
                <div
                  className="flex flex-col w-full md:w-1/2 
                  bg-primary-lighter border-2 border-primary-lighter rounded-xl"
                >
                  <div className="pt-6 pb-2 text-center leading-snug">
                    <h3 className="font-semibold text-gray-dark text-2xl mb-2">
                      {preplanning_title.text}
                    </h3>
                    <div className="text-sm px-4">
                      {parse(preplanning_subtitle.html)}
                    </div>
                  </div>
                  <div className="flex-grow w-full bg-grey-lighter rounded-b-xl px-4 pt-4 pb-6">
                    <MembershipPickerGroup
                      memberships={premiumMemberships}
                      onPlanSelect={onPlanSelect}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        {isUpgradeFlowVisible && (
          <UpgradeFlow
            paywallComponent={UpgradePaywallModal}
            onCancel={() => setIsUpgradeFlowVisible(false)}
            onSuccess={() => {
              setIsUpgradeFlowVisible(false)
            }}
          />
        )}
      </PricingProvider>
      <Newsletter location="PricingPage Newsletter" />
    </Layout>
  )
}

export default PricingPage
